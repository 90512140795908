<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Motorista" : "Cadastrar Motorista" }}
    </v-subheader>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações Principais</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="primary--text">Nome *</span>
              <v-text-field
                placeholder="Informe o nome"
                class="mt-n1"
                v-model="dados.nome"
                :rules="fieldRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <span class="primary--text">CPF *</span>
              <v-text-field
                placeholder="Informe o cpf"
                class="mt-n1"
                v-model="dados.cpf"
                :rules="fieldRules"
                v-mask-cpf
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <span class="primary--text">Estado Civil *</span>
              <v-select
                :items="[
                  'SOLTEIRO',
                  'CASADO',
                  'SEPARADO',
                  'DIVORCIADO',
                  'VIÚVO',
                  'AMASIADO',
                ]"
                v-model="dados.estado_civil"
                :rules="fieldRules"
                required
                placeholder="Informe o estado civil"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <span class="primary--text">Identidade *</span>
              <v-text-field
                placeholder="Informe a identidade"
                class="mt-n1"
                v-model="dados.identidade"
                :rules="fieldRules"
                required
                v-mask-number
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <span class="primary--text">CNH</span>
              <v-text-field
                placeholder="Informe a cnh"
                class="mt-n1"
                v-model="dados.numero_cnh"
                v-mask-number
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="2">
              <span class="primary--text">Categoria</span>
              <v-select
                v-model="dados.categoria"
                :items="[
                  'A',
                  'ACC',
                  'B',
                  'C',
                  'D',
                  'E',
                  'AB',
                  'AC',
                  'AD',
                  'AE',
                ]"
                placeholder="Informe a categoria"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <span class="primary--text">Primeira CNH</span>
              <v-text-field
                placeholder="Informe a primeira cnh"
                class="mt-n1"
                v-model="dados.primeira_cnh"
                v-mask-date.br
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <span class="primary--text">Vencimento</span>
              <v-text-field
                placeholder="Informe o vencimento"
                class="mt-n1"
                v-model="dados.vencimento"
                v-mask-date.br
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Aplicativo Motorista</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="12">
              <span class="primary--text">Senha</span>
              <v-text-field
                placeholder="Informe a senha para acesso ao aplicativo"
                class="mt-n1"
                v-model="dados.senha"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações Pessoais</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <span class="primary--text">Telefone</span>
              <v-text-field
                placeholder="Informe o telefone"
                class="mt-n1"
                v-model="dados.telefone"
                v-mask-phone.br
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <span class="primary--text">Data de Nascimento</span>
              <v-text-field
                placeholder="Informe a data de nascimento"
                class="mt-n1"
                v-model="dados.data_nascimento"
                v-mask-date.br
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações de Endereço</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <span class="primary--text">CEP</span>
              <v-text-field
                placeholder="Informe o cep"
                class="mt-n1"
                v-model="dados.cep"
                @blur="getCep(dados.cep)"
                :loading="loading"
                v-mask-cep.br
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <span class="primary--text">Logradouro</span>
              <v-text-field
                placeholder="Informe o logradouro"
                class="mt-n1"
                v-model="dados.logradouro"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <span class="primary--text">Número</span>
              <v-text-field
                placeholder="Informe o numero"
                class="mt-n1"
                v-model="dados.numero"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <span class="primary--text">Bairro</span>
              <v-text-field
                placeholder="Informe o bairro"
                class="mt-n1"
                v-model="dados.bairro"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <span class="primary--text">Cidade</span>
              <v-text-field
                placeholder="Informe a cidade"
                class="mt-n1"
                v-model="dados.cidade"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <span class="primary--text">UF</span>
              <v-text-field
                placeholder="UF"
                class="mt-n1"
                v-model="dados.uf"
                v-mask="'UU'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Percentual de Comissōes</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="12" md="3">
              <span class="primary--text">Transportados %</span>
              <v-text-field
                placeholder="Transportados %"
                class="mt-n1"
                v-model="dados.comissao_vp"
                v-mask-decimal.br="2"
              />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <span class="primary--text">V.Motorista %</span>
              <v-text-field
                placeholder="V.Motorista %"
                class="mt-n1"
                v-model="dados.comissao_e"
                v-mask-decimal.br="2"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn depressed dark text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        nome: "",
        data_nascimento: "",
        cpf: "",
        estado_civil: "",
        identidade: "",
        numero_cnh: "",
        categoria: "",
        orgao_expedidor: "",
        primeira_cnh: "",
        vencimento: "",
        telefone: "",
        logradouro: "",
        bairro: "",
        cep: "",
        cidade: "",
        uf: "",
        comissao_vp: 0,
        comissao_e: 0,
        senha: "",
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      show: false,
    };
  },
  created() {
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/motoristas/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.dados.nome = this.dados.nome
        ? this.dados.nome.toUpperCase()
        : this.dados.nome;
      this.dados.logradouro = this.dados.logradouro
        ? this.dados.logradouro.toUpperCase()
        : this.dados.logradouro;
      this.dados.bairro = this.dados.bairro
        ? this.dados.bairro.toUpperCase()
        : this.dados.bairro;
      this.dados.cidade = this.dados.cidade
        ? this.dados.cidade.toUpperCase()
        : this.dados.cidade;
      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/motoristas/update/${this.id}`, this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros/motoristas");
              }, 500);
            } else {
              this.message.text = r.data.error;
              this.message.snacshow = true;
              this.message.type = "error";
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/motoristas/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros/motoristas");
              }, 1500);
            } else {
              this.message.text = r.data.error;
              this.message.snacshow = true;
              this.message.type = "error";
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
    getCep(cep) {
      if (cep.length == 10) {
        this.loading = true;
        cep = cep.replace("-", "");
        cep = cep.replace(".", "");
        axios
          .get("https://viacep.com.br/ws/" + cep + "/json/")
          .then((response) => {
            this.dados.logradouro = response.data.logradouro;
            this.dados.bairro = response.data.bairro;
            this.dados.cidade = response.data.localidade;
            this.dados.uf = response.data.uf;
            this.loading = false;
          })
          .catch((e) => {
            this.message.snacshow = true;
            this.message.type = "warning";
            this.message.text = "Atencão cep não encontrado!" + e;
            this.loading = false;
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
